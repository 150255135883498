/* Floating Button */
.floating-btn {
  position: fixed;
  z-index: 100;
  right: 1rem;
  bottom: 1rem;
}
.contact-btn {
  width: 60px;
  height: 60px;
  background-color: #00ae09;
  border: 2px solid #00ae09;
  border-radius: 50%;
}
.contact-icon {
  color: #FFF;
  font-size: 28px;
}
@media (max-width: 767px) {
  .contact-btn {
    width: 50px;
    height: 50px;
  }
  .contact-icon {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .floating-btn {
    display: none;
  }
}


/* General Styling */
.action-button {
  border: 2px solid #dec380;
  background-color: #dec380;
  border-radius: 10px;
  padding: 5px 30px;
}
@media (max-width: 991px) {
  .action-button {
    padding: 5px 20px;
    margin-top: 5px;
  }
}

.action-button p {
  font-size: 16px;
}
@media (max-width: 575px) {
  .action-button p {
    font-size: 14px;
  }
}
.action-button:hover {
  transition: 0.5s;
  background: #ffd745;
  border: 2px solid #ffd745;
}

.header-button {
  padding: 0px;
}

.header-button img {
  width: 10%;
  margin-right: 10px;
}

.header-button p {
  font-size: 16px;
}
@media (max-width: 575px) {
  .header-button p {
    font-size: 14px;
  }
}

.header-button i {
  font-size: 20px;
}

.user-wallet-icon img {
  margin-right: 10px;
  max-height: 35px;
}
@media (max-width: 376px) {
  .user-wallet-icon img {
    margin-right: 5px;
    max-height: 25px;
  }
}

.lang {
  padding: 0 20px;
}
@media (max-width: 376px) {
  .lang {
    padding: 0 15px;
  }
}

.search-bar {
  position: relative;
  width: 85%;
  border: 1px solid #dec380;
  border-radius: 10px;
}

.search-input {
  width: 90%;
  background: #202629;
  border: none;
  border-radius: 15px;
  font-family: 'outfit-light';
  outline: none;
  color: white;
  padding: 5px 10px;
}

.search-bar i {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.unselected-btn {
  background-color: #1c202e;
  border: 2px solid #1c202e;
}

.remove-btn {
  background-color: #af0000;
  border: 2px solid #af0000;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .remove-btn {
    padding: 5px 20px;
    margin-top: 5px;
  }
}
.remove-btn:hover {
  transition: 0.5s;
  background: #860101;
  border: 2px solid #860101;
}

.ok-btn {
  background-color: #bdff6d;
  border: 2px solid #bdff6d;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .ok-btn {
    padding: 5px 20px;
    margin-top: 5px;
  }
}
.ok-btn:hover {
  transition: 0.5s;
  background: #99ff1c;
  border: 2px solid #99ff1c;
}

.timestamp-text {
  color: #404040;
  font-size: 16px;
}

.form-logo {
  width: 100%;
  max-width: 200px;
}
@media (max-width: 991px) {
  .form-logo {
    max-width: 160px;
  }
}

.secondary-btn {
  background-color: #a0a0a0;
  border: 2px solid #a0a0a0;
  border-radius: 10px;
}
.secondary-btn:hover {
  transition: 0.5s;
  background: #818181;
  border: 2px solid #818181;
}


.heading-box {
  background-color: #a28b51;
  border-radius: 10px;
  padding: 5px 20px;
}



/* Header Styling */
.header {
  background-color: #13171a;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  /* border-bottom: 1px solid #e9d096; */
  transition: padding-left 0.6s ease;
  display: block;
}

.header-mobile {
  position: relative;
  width: 100%;
  display: none;
}

.mobile-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: none;
  background-color: #13171a;
}
@media (max-width: 426px) {
  .mobile-nav p {
    font-size: 12px;
  }
}

.mobile-nav div {
  width: 20%;
}

.nav-icon {
  width: 3em;
  height: 3em;
  padding: 0;
  background-color: #e9d096;
  border-radius: 50%;
}

.nav-icon i {
  font-size: 25px;
}

.collapse-menu-btn {
  width: 3em;
  height: 3em;
  padding: 0;
}

.collapse-btn-img {
  width: 80%;
}

@media (max-width: 556px) {
  .nav-icon, .collapse-menu-btn {
    width: 2.5em;
    height: 2.5em;
  }
  .nav-icon i {
    font-size: 20px;
  }
}
@media (max-width: 556px) {
  .collapse-btn-img {
    width: 70%;
  }
}
@media (max-width: 376px) {
  .nav-icon, .collapse-menu-btn {
    width: 2em;
    height: 2em;
  }
  .nav-icon i {
    font-size: 15px;
  }
}

.header-collapsed {
  padding-left: 80px; /* Adjust this based on collapsed sidebar width */
}

.header-expanded {
  padding-left: 260px; /* Adjust this based on expanded sidebar width */
}

.side-menu-logotext {
  width: 100%;
  max-height: 60px;

}
@media (max-width: 769px) {
  .header {
    display: none;
  }
  .header-mobile, .mobile-nav {
    display: block;
  }
  .header-nav {
    margin: 10px;
    background-color: #13171a;
    border-radius: 15px;
  }
  .header-collapsed {
    padding-left: 0; /* Adjust this based on collapsed sidebar width */
  }
  .header-expanded {
    padding-left: 0; /* Adjust this based on expanded sidebar width */
  }
  .side-menu-logotext {
    max-height: none;
    max-width: 100px;
  }
}


/* Side Bar Styling */
.ps-sidebar-container, .ps-submenu-content {
  /* background-color: #202629 !important; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
@media (max-width: 767px) {
  .ps-sidebar-container, .ps-submenu-content {
    background-color: #292732 !important;
  }
}
.ps-sidebar-container::-webkit-scrollbar, .ps-submenu-content::-webkit-scrollbar {
  display: none;
}

.ps-submenu-content {
  min-width: 260px !important;
}

.side-bar-container {
  height: 100vh;
  position: fixed;
  z-index: 120;
}

.side-menu-brand-area {
  width: 100%;
  padding: 10px 15px;
  display: flex;
}

.side-menu-logo {
  width: 100%;
}

.collapse-icon {
  color: #DCDCDC;
  font-size: 18px;
}

.side-menu-icon {
  font-size: 22px;
}

.side-menu-text {
  margin-top: 18px;
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.side-menu-coming-soon, .side-menu-off, .side-menu-on, .side-menu-new, .side-menu-maintenance {
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 13px;
}
@media (max-width: 575px) {
  .side-menu-coming-soon, .side-menu-off, .side-menu-on, .side-menu-new, .side-menu-maintenance {
    font-size: 12px;
  }
}
.side-menu-coming-soon {
  color: #000;
  background-color: #c45500;
}
.side-menu-off {
  background-color: #830000;
}
.side-menu-on {
  background-color: #008311;
}
.side-menu-new {
  color: #000;
  background-color: #1CFFD2;
}
.side-menu-maintenance {
  color: #000;
  background-color: #e8c100;
}




/* Main Content Area */
.main-content {
  width: 100%;
  height: calc(100vh - 80px);
  margin-top: 80px;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.main-content::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .main-content {
    margin-top: 0;
    padding-bottom: 120px;
  }
}

.section-heading {
  font-size: 38px;
}
@media (max-width: 991px) {
  .section-heading {
    font-size: 32px;
  }
}
@media (max-width: 575px) {
  .section-heading {
    font-size: 28px;
  }
}

.slider-title {
  width: 15%;
}
.hot-games-category h5 { 
  font-size: 12px;
}
@media (max-width: 1025px) {
  .slider-title {
    width: 20%;
  }
}
@media (max-width: 769px) {
  .slider-title {
    width: 30%;
  }
}
@media (max-width: 426px) {
  .slider-title {
    width: 50%;
  }
}
.breadcrumbs-link {
  color: #00b6ee;
  font-size: 14px;
}
.breadcrumbs-link a {
  color: #00b6ee;
}

.swiper {
  border-radius: 15px;

}
.page-banner {
  width: 100%;
  /* border-radius: 15px; */
}

.currency-sm {
  font-family: 'outfit-light';
  font-weight: 700;
  font-size: 20px;
}

.currency-lg {
  font-family: 'outfit-light';
  font-weight: 700;
  font-size: 28px;
}
@media (max-width: 991px) {
  .currency-lg {
    font-size: 24px;
  }
}

.game-box {
  /* border: 1px solid #414141; */
  border-radius: 10px;
  /* background-color: #0d0d0d; */
  /* padding: 3px; */
}

.page-container, .page-sub-container {
  border: 1px solid #414141;
  border-radius: 10px;
  background-color: #0d0d0d;
}
.page-container {
  padding: 30px;
}
@media (max-width: 767px) {
  .page-container {
    padding: 20px;
  }
}
.page-sub-container {
  padding: 15px 20px;
}
@media (max-width: 767px) {
  .page-sub-container {
    padding: 15px;
  }
}



/* Affiliate */
.referral-link {
  font-size: 18px;
}
@media (max-width: 767px) {
  .referral-link {
    font-size: 16px;
  }
}

/* Leaderboard */
table {
  overflow-x: scroll;
}


.game-category {
  width: 100%;
}

.game-category-cover {
  width: 100%;
  border-radius: 10px;
}

.hot-games-img {
  width: 100%;
  border-radius: 10px;
  background-color: #0d0d0d;
}

.game-provider-img {
  width: 100%;
  border-radius: 10px;
}

.game-container {
  background-color: #161616;
  border-radius: 10px;
  padding: 5px;
  max-width: 200px;
}






/* Brands & Logos */
.brand-logo {
  width: 100%;
  max-width: 200px;
}
@media (max-width: 575px) {
  .brand-logo {
    max-width: 180px;
  }
}



.game-frame-item {
  border: none;
  width: 100%;
  height: 80vh;
}






.community-section {
  background-color: #000;
  padding: 60px 10px 100px 10px;
}

.community-logo {
  width: 200px;
}

.social-link {
  color: #DCDCDC;
  text-decoration: none;
}

.social-icon {
  color: #DCDCDC;
  font-size: 36px;
}
@media (max-width: 767px) {
  .social-icon {
    font-size: 28px;
  }
}
.social-icon:hover {
  transition: 0.5s;
  cursor: pointer;
  scale: 1.05;
}




/* Footer */
.footer-logo {
  width: 160px;
}
.copyright-footer {
  padding-top: 10px;
  padding-bottom: 5px;
  border-top: 1px solid #e9d096;
}
.copyright-text {
  font-size: 12px;
  color: #e9d096;
}


/* Pagination */
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: auto;
  padding: 30px 0;
}

.pagination-container button {
  padding: 0 5px;
  color: #e9d096;
  border: none;
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 0;
}

.pagination-container button.active {
  color: #fff;
  font-weight: bold;
}

.pagination-container button:hover,
.pagination-container button:focus {
  outline: none;
}

.pagination-container button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}




.dropdown-button {
  white-space: normal; 
  word-wrap: break-word; 
  overflow: visible;
}



/* Bets Table */
.table {
  margin: 0;
}

.table thead th,
.table td,
.table th {
  border: none!important;
  padding: 5px;
  vertical-align: middle;
}

.table td {
  font-size: 10px;
}

.table thead th {
  font-size: 10px;
}

@media (min-width: 375px) {
  .table thead th {
    font-size: 12px;
  }
  .table td {
    font-size: 11px;
  }
}
@media (min-width: 576px) {
  .table thead th {
    font-size: 16px;
  }
  .table td {
    font-size: 13px;
  }
}

.tabs {
  background-color: #161616;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-radius: 6px;
}

.nav-item {
  flex: 1; /* Ensures each <li> takes up equal space */
  text-align: center; /* Centers the content inside each tab */
}

.tabs-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  font-size: 12px;
  color: #9e9e9e;
  background-color: transparent;
  border: none;
  border-radius: 6px;
}

@media (min-width: 576px) {
  .tabs {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .nav-item {
    flex: none;
  }
}

.tab-active {
  color: #ffffff;
  background-color: #404040;
}

.bets-table,
.roller-table,
.wager-top-content,
.wager-table {
  background-color: #161616;
  padding: 10px;
  border-radius: 10px;
}

.wager-top-content {
  font-size: 14px;
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
    font-family: 'helvetica';
    src: url('../fonts/helvetica-webfont.woff2') format('woff2'),
         url('../fonts/helvetica-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-regular';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'outfit-light';
    src: url('../fonts/Outfit-Light.woff2') format('woff2'),
         url('../fonts/Outfit-Light.woff') format('woff');
}

@font-face {
    font-family: 'outfit-medium';
    src: url('../fonts/Outfit-Medium.woff2') format('woff2'),
         url('../fonts/Outfit-Medium.woff') format('woff');
}

.brand-gold {color:#e9d096;}
.brand-yellow {color:#a17b1c;}
.light-yellow {color:#ffe092;}
.active-yellow {color:#a17b1c;}

.vip-yellow {color: #ffd876;}
.error {color:#e10909;}
.disabled {color: #505050;}
.profit-green {color:#48b30e;}
.profit-red {color:#e10909;}
.text-gray {color:#dcdcdc;}
.orange {color:#c45500;}

.gold-border-right {
    border-right: 1px solid #e9d096;
}
.gold-border-left {
    border-left: 1px solid #e9d096;
}
.gold-border-top {
    border-top: 1px solid #e9d096;
}
.gold-border {
    border: 2px solid #e9d096;
}

.bg-black {background-color: #161616;}

.bg-gold {background-color: #e9d096!important;}

.bg-purpleblack {background-color: #1d1c28;}

.maintenance-icon {
    font-size: 64px;
    color: #c45500;
}

body {
    margin:0;
    padding:0;
    overflow: hidden;
    background-color: #292732;
}
@media (max-width: 768px) {
    body {
        background-color: #292732;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'outfit-medium';
    font-optical-sizing: auto;
    font-weight: 700;
}

@media (max-width: 575px) {
    h5 {font-size: 16px;}
}

a, a:hover {
    text-decoration: none;
}

button:focus {
    outline: none!important;
}

p, a, button, .table {
    font-family: 'outfit-light';
    /* font-family: "Noto Sans", sans-serif; */
    font-size: 16px;
}
@media (max-width: 575px) {
    p {font-size: 14px;}
}

.fluid-padding {
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 1200px) {
    .fluid-padding {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media (min-width: 1600px) {
    .fluid-padding {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.form-page-padding {
    padding-left: 5%;
    padding-right: 5%;
}
@media (min-width: 767px) {
    .form-page-padding {
        padding-left: 15%;
        padding-right: 15%;
    }
}

.desktop-sm {
    display: block;
}
@media (max-width: 575px) {
    .desktop-sm {
        display: none;
    }
}
.mobile-sm {
    display: none;
}
@media (max-width: 575px) {
    .mobile-sm {
        display: block;
    }
}
.desktop-md {
    display: block;
}
@media (max-width: 767px) {
    .desktop-md {
        display: none;
    }
}
.mobile-md {
    display: none;
}
@media (max-width: 767px) {
    .mobile-md {
        display: block;
    }
}
.desktop-lg {
    display: block;
}
@media (max-width: 991px) {
    .desktop-lg {
        display: none;
    }
}
.mobile-lg {
    display: none;
}
@media (max-width: 991px) {
    .mobile-lg {
        display: flex;
    }
}

.divider {
    border-top: 1px solid #e9d096;
}

.btn-link:hover {
    color:#ffe092!important;
}